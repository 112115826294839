import React, {FunctionComponent, useEffect, useState} from 'react';
import Header from './Header';
import Footer from './Footer';
// import CookieBar from './CookieBar';
import {usePermissionData} from '../../lib/permissions/PermissionContext';
import TagManager from 'react-gtm-module';
import {useGoogleAnalytics} from '../../lib/permissions/googleUA';
import OneTrustManager from '../../lib/otscript';


const Layout: FunctionComponent = ({children}) => {
    const [mounted, setMounted] = useState(false);
    const {state} = usePermissionData();

    useEffect(() => {
        // In order to only show the cookiebar when the layout has been mounted to prevent cookiebar flash when it has already been accepted.
        setMounted(true);
    }, []);

    useEffect(() => {
        // if (state.allowTracking) {

            let dataLayer = {
                'LoginVariant': state.bigCode ? 'BIG' : state.euCode ? 'EU' : 'None'
            };

            // useGoogleAnalytics();
            TagManager.initialize({...{gtmId: process.env.GTM as string}, ...dataLayer});
        // }
    }, [state.allowTracking]);

    function isIE() {

        if (typeof window !== 'undefined') {
            const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
            const msie = ua.indexOf('MSIE '); // IE 10 or older
            const trident = ua.indexOf('Trident/'); //IE 11

            return (msie > 0 || trident > 0);
        }

        return false;
    }

    return (
        <>
            <Header/>
            {isIE() &&
            <div className={'bg-red-100 border border-red-400 text-red-700 mx-4 my-4 px-4 py-3 rounded relative'}>IE 11 en lager
                worden
                niet meer ondersteunt. Voor de beste gebruikerservaring en veiligheid gebruikt u Microsoft Edge, Google chrome, of
                Firefox. Klik <a className='underline' href={'https://browsehappy.com/'}>hier</a> om een andere browser te
                gebruiken.
            </div>}

            {children}

            <Footer/>
            <OneTrustManager id={'33b4c561-473a-4a02-ad83-002e120d0c73'}/>
        </>
    );
};

export default Layout;
